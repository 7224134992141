import React, { useState, useEffect } from "react";

import FacebookLogin from "react-facebook-login";
import { Card, Image } from "react-bootstrap";

function Facebook() {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");

  const responseFacebook = (response) => {
    console.log(response);
    setData(response);
    setPicture(response.picture.data.url);
    if (response.accessToken) {
      document.querySelector("a.navbar-brand").click();
      localStorage.setItem("loginaccesstokec", response.accessToken);

      localStorage.setItem("email", response.email);
      localStorage.setItem("username", response.name);
      let access = localStorage.getItem("loginaccesstokec");
      let email = localStorage.getItem("email");
      let username = localStorage.getItem("username");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ token: access });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/api/Account/FacebookLogin`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var json = JSON.parse(result);

          if (json.userName === email) {
            document.querySelector(".registerbutton").style.display = "none";
            document.querySelector(".loginbutton").style.display = "none";
            document.querySelector(".logoutbutton").style.display = "block";
            document.querySelector(".welocmetext").style.display = "block";
            document.getElementById("Name").innerHTML =
              localStorage.getItem("username");

            console.log(access);
          } else {
            alert("User not exist");
            localStorage.clear();
          }
        })
        .catch((error) => console.log("error", error));

      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  return (
    <>
      {!login && (
        <FacebookLogin
          appId="408422204169974"
          textButton=""
          autoLoad={false}
          fields="name,email,picture"
          scope="public_profile,user_friends,email"
          callback={responseFacebook}
          icon="fa-facebook"
          cookie={true}
        />
      )}
    </>
  );
}

export default Facebook;
