import { graphql, useStaticQuery } from "gatsby";
import FacebookLogin from "react-facebook-login";
import Facebook from "./Facebook";
import { StaticImage } from "gatsby-plugin-image";
import { navigate, Link } from "gatsby";
import { faAngleLeft, faEye, faStar } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Google from "./Google";
import Logo from "./Logo";
import React, { useState, useEffect } from "react";
import { isLoggedIn, logout, saveUser } from "../utils/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header() {
  const eye = <FontAwesomeIcon icon={faEye} />;
  const [login, setLogin] = useState(false);
  const [dataa, setData] = useState({});
  const [picture, setPicture] = useState("");
  const [loginShow, setloginShow] = useState(null);

  useEffect(() => {
    CheckLogin();
  }, []);

  const [PasswordType, setPasswordType] = useState(true);

  async function CheckLogin() {
    var a = await isLoggedIn();
    setloginShow(a);
  }

  const responseFacebook = (response) => {
    console.log(response);
    setData(response);
    setPicture(response.picture.dataa.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };
  const urlactive =
    typeof window !== "undefined" ? window.location.href.split("/")[3] : "";
  const data = useStaticQuery(graphql`
    query {
      allMenuJson(
        sort: { fields: Order, order: ASC }
        filter: { CTAText: { ne: null } }
      ) {
        edges {
          node {
            CTALink
            CTAText
            Order
          }
        }
      }
    }
  `);
  function Logout() {
    localStorage.clear();
    document.querySelector("a.navbar-brand").click();
  }
  const handleSubmit = (e) => {
    let usernameinputvalue = document.getElementById("MyEmail").value;
    let passwordinputvalue = document.getElementById("MyPassword").value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=F8WF1tse1m4jPeVmq_y8v1PigUs5Dd7FfbcqJis39hCNEd1j5bfcb5Of5jeqAps4iebShIkJsGxTzQyGDEnIEqIUmZdG5PJzsXSHx2OS8UvrB-N8u1DhQeMksnPhA2IiV9j2GcDR-BIfGoJCvztFJRfnwSNhDftqKTUDcA68GTeji3c1fPA0VCIO7GdUeJ20Cu-VsZ5YqlTd7kjn15jHBF5xKy2_xuxvSy-UXNE_H9PEasutlUqZBrqVSOgRiAFC3aw_bZ9A1f-ER3L3apeVplyTs2gA_RbKd7JFhiKDiUvLHaBhWKbAu56hYy1_4CDnZBXqvGi5rdFe3siOTawWwlGj-qNhCStSuCDk2SL-DXmgktBbDYgpEF9KU_9Qv2zcahH5ncA1WUiFbkykRsowRvIXsOOxUIrtEcrEhi7DSv1ThsRP_MmGkK92wgjXcXwtAsUJOvK9VnrdWnGMOayErv02949RlnYs5oqHNiV_YVi7j9eC0jFAje45YrEoFrBgQJaHG0RIndTeD6GHaK3vQg"
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("password", passwordinputvalue);
    urlencoded.append("username", usernameinputvalue);
    urlencoded.append("grant_type", "password");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/token`, requestOptions)
      .then((response) => {
        /* if (response.status === 200) {
          navigate("/app/dashboard");
        } */
        return response?.text() ?? "";
      })

      .then(async (result) => {
        var json = JSON.parse(result);
        if (
          json?.error_description === "The user name or password is incorrect."
        ) {
          document.querySelector(".addressheaderror").style.display = "block";
        } else {
          document.querySelector(".addressheaderror").style.display = "none";
        }
        if (json.access_token) {
          localStorage.setItem("loginaccesstokec", json.access_token);
          localStorage.setItem("loginemail", json.userName);

          saveUser({
            name: json?.userName ?? "",
            email: json?.userName ?? "",
            token: json.access_token ?? "",
          });
          CheckLogin();
          navigate(`/app/dashboard`);

          var eleName = document.getElementById("Name");
          if (eleName) eleName.innerHTML = localStorage.getItem("loginemail");
        }
      })
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };

  return (
    <header>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="white"
        className="py-xl-5"
      >
        <Container className="box-nav">
          <Link to="/">
            <Logo />
          </Link>
          <div className="ms-auto d-flex align-items-center">
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="navbar-light"
            />
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              {data.allMenuJson.edges.map(({ node, index }) => {
                return (
                  <>
                    <Link
                      className={
                        "/" + urlactive === node.CTALink
                          ? "nav-link activetext"
                          : "nav-link"
                      }
                      to={node.CTALink}
                      key={index}
                    >
                      {node.CTAText}
                    </Link>
                  </>
                );
              })}
            </Nav>
            <Nav className="align-items-center ">
              {loginShow && (
                <>
                  <div className="welocmetext">
                    {/*  <a
                      class="text-primarylocal"
                      href="javascript:void(0)"
                      onClick={() => navigate("/add-location")}
                    >
                      Add Location
                    </a> */}
                    <Button
                      className="ms-xl-3 ms-lg-3 ms-md-0 ms-0 nav-button "
                      onClick={() => navigate(`/create-campaign`)}
                    >
                      Create Campaign
                    </Button>
                  </div>
                  <Button
                    onClick={() => {
                      logout(async () => {
                        await CheckLogin();
                        navigate(`/`);
                      });
                    }}
                    className="ms-xl-3 ms-lg-3 ms-md-0 ms-0 nav-button logoutbutton"
                  >
                    Log Out
                  </Button>
                </>
              )}

              {loginShow === false && (
                <>
                  <Dropdown className="pe-xl-4 pe-lg-4 pe-md-0 pe-0 pe-sm-0 loginbutton">
                    <DropdownButton
                      key="bottom"
                      id={`dropdown-button-drop-bottom`}
                      drop="bottom"
                      variant="link"
                      className="login-drop"
                      title={`Login`}
                    >
                      <form className="my-4 text-start" onSubmit={handleSubmit}>
                        <p
                          className="addressheaderror"
                          style={{
                            display: "none",
                            fontSize: "11px",
                            color: "red",
                          }}
                        >
                          The user name or password is incorrect.
                        </p>

                        <div className="mb-2">
                          <Form.Group controlId="MyEmail" className="mb-4">
                            <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                              Email
                            </Form.Label>
                            <Form.Control type="email" />
                          </Form.Group>
                          <Form.Group
                            controlId="formGridPassword"
                            className=" pass-wrapper"
                          >
                            <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                              Password
                            </Form.Label>
                            <Form.Control
                              id="MyPassword"
                              type={PasswordType ? "password" : ""}
                            />
                            <i
                              role="button"
                              onClick={() => setPasswordType((x) => !x)}
                              aria-hidden="true"
                              style={{ marginTop: "11px" }}
                            >
                              {eye}
                            </i>
                          </Form.Group>
                        </div>
                        <p className="primary-color fw-500 mb-2 text-primarylocal">
                          <a
                            className="text-primarylocal"
                            href="/forgotpassword"
                          >
                            Forgot Password
                          </a>
                        </p>
                        <Button
                          variant="primary"
                          className="w-100 fs-14 fw-bold h-52"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </form>
                      <Row className="text-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 mb-4">
                          <Card className="border-0 card-login">
                            <Card.Body className="googlecss">
                              <Google />
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 mb-4">
                          <Card className="border-0  card-login">
                            <Card.Body className="facebookcss">
                              <Facebook />
                            </Card.Body>
                          </Card>
                        </div>
                      </Row>
                    </DropdownButton>
                  </Dropdown>
                  <Link
                    className="ms-xl-3 ms-lg-3 ms-md-0 ms-0 nav-button registerbutton text-decoration-none text-white"
                    to="/register"
                  >
                    Register
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
