import React, { useState, useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";
import Footer from "./Footer";
import Header from "./Header";
import "react-notifications-component/dist/theme.css";
import { isLoggedIn } from "../utils/auth";
import { navigate } from "gatsby";

const Layout = ({ children, displayHeader = true }) => {
  useEffect(() => {
    window.$apiurl = "https://app.thebogoapp.com";
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      var a = (children?._owner?.key ?? "").includes("/register");
      if (a) navigate(`/app/dashboard`);
      return false;
    }
  }, [children]);

  return (
    <div>
      {displayHeader && <Header />}
      {displayHeader && <ReactNotifications />}
      <div className="content">{children}</div>
      {displayHeader && <Footer />}
    </div>
  );
};

export default Layout;
