import React from "react";
import { useGoogleLogin } from "react-google-login";

// refresh token
import { refreshTokenSetup } from "../components/refreshToken";
import { GoogleLogin } from "react-google-login";

function Google() {
  const responseGoogle = (response) => {
    if (response.Zb.id_token) {
      localStorage.setItem("loginaccesstokec", response.Zb.id_token);
      localStorage.setItem("googleemail", response.profileObj.email);
      var googleaccess = localStorage.getItem("loginaccesstokec");
      var googleemail = localStorage.getItem("googleemail");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ token: googleaccess });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/api/Account/GoogleLogin`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var json = JSON.parse(result);

          if (json.userName === googleemail) {
            document.querySelector(".registerbutton").style.display = "none";
            document.querySelector(".loginbutton").style.display = "none";
            document.querySelector(".logoutbutton").style.display = "block";
            document.querySelector(".welocmetext").style.display = "block";
            document.getElementById("Name").innerHTML =
              localStorage.getItem("googleemail");
          } else {
            alert("User not exist");
            localStorage.clear();
          }
        })

        .catch((error) => console.log("error", error));
    }
  };
  return (
    <GoogleLogin
      clientId="909132955220-9uo2hcf2ov2cpqe1p14g35s0qattb2m2.apps.googleusercontent.com"
      buttonText=""
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
}

export default Google;
