const isBrowser = typeof window !== `undefined`;

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {};

const setUser = (user) =>
  (window.localStorage.gatsbyUser = JSON.stringify(user));

export const handleLogin = ({ username, password }) => {
  if (!isBrowser) return false;

  if (username === `gatsby` && password === `demo`) {
    console.log(`Credentials match! Setting the active user.`);
    return setUser({
      name: `Jim`,
      legalName: `James K. User`,
      email: `jim@example.org`,
    });
  }

  return false;
};

export const saveUser = ({ name, email, token, type, tagy }) => {
  if (!isBrowser) return false;

  console.log(`Credentials match! Setting the active user.`);
  return setUser({
    name: name,
    legalName: name,
    email: email,
    token: token,
    type: type,
    tagy: tagy,
  });
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;
  const user = getUser();
  return !!user.email;
};

export const getToken = () => {
  if (!isBrowser) return false;
  const user = getUser();
  return user.token ?? false;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback) => {
  if (!isBrowser) return;

  console.log(`Ensuring the \`gatsbyUser\` property exists.`);
  setUser({});
  callback();
};
